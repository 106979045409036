import Vue from 'vue';
import Vuex from 'vuex';

import dictionary from './modules/dictionary';
import document from './modules/document';
import alert from './modules/alert';
import file from './modules/file';
import univarsal from './modules/universal';
import report from './modules/report';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: '1.05c',
    isAuth: false,
    token: '',
    authLoading: false,
    userId: '',
    role: 1,
    serverURI: process.env.VUE_APP_GRAPHQL
  },
  getters: {
    getRole: state => {
      return state.role;
    },
    getUserId: state => {
      return state.userId;
    },
    getToken: state => {
      return state.token;
    }
  },
  mutations: {
    setLogin(state, { token, userId, role }) {
      state.isAuth = true;
      state.authLoading = true;
      state.token = token;
      state.userId = userId;
      state.role = role;
    },
    setLogout(state) {
      state.isAuth = false;
      state.authLoading = false;
    }
  },
  actions: {},
  modules: {
    dictionary,
    alert,
    document,
    file,
    univarsal,
    report
  }
});
