import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/404',
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'main',
      auth: true
    },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '/askreset',
    name: 'AskReset',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/AskReset.vue')
  },
  {
    path: '/reset',
    name: 'ResetForm',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Reset.vue')
  },
  {
    path: '/reset/:token',
    name: 'ResetFormToken',
    meta: {
      layout: 'empty'
    },
    props: true,
    component: () => import('../views/Reset.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Register.vue')
  },
  {
    path: '/dictionary',
    name: 'Dictionary',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Dictionary.vue')
  },
  {
    path: '/document',
    name: 'Document',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Document.vue')
  },
  {
    path: '/connections',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () =>
      import('../components/documents/connection/Connection.vue'),
    children: [
      {
        path: '',
        meta: {
          layout: 'main',
          auth: true
        },
        name: 'Connections',
        component: () => import('../components/documents/connection/List.vue')
      },
      {
        path: 'create',
        meta: {
          layout: 'main',
          auth: true
        },
        name: 'CreateConnection',
        component: () =>
          import('../components/documents/connection/CreateEdit.vue')
      },
      {
        path: ':id/edit',
        meta: {
          layout: 'main',
          auth: true
        },
        props: true,
        name: 'EditConnection',
        component: () =>
          import('../components/documents/connection/CreateEdit.vue')
      }
    ]
  },
  {
    path: '/connection',
    name: 'ConnectionDetailIndex',
    meta: {
      layout: 'main',
      auth: true
    },
    props: true,
    component: () =>
      import('../components/documents/connection/Detail/Index.vue'),
    children: [
      {
        path: ':id',
        name: 'DetailConnection',
        meta: {
          layout: 'main',
          auth: true
        },
        props: true,
        component: () =>
          import('../components/documents/connection/Detail/Detail.vue')
      },
      {
        path: ':id/:page',
        name: 'DetailConnectionThisPage',
        meta: {
          layout: 'main',
          auth: true
        },
        props: true,
        component: () =>
          import('../components/documents/connection/Detail/Detail.vue')
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Report.vue')
  },
  {
    path: '/images',
    name: 'Images',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/ImageUploader.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/provider',
    name: 'Provider',
    meta: {
      layout: 'main',
      auth: true,
      role: 2
    },
    component: () => import('../views/Provider.vue'),
    children: [
      {
        path: ':id',
        name: 'OperationsWindow',
        meta: {
          layout: 'main',
          auth: true
        },
        props: true,
        component: () =>
          import('../components/documents/provider/OperationsWindow.vue')
      }],
  },
  {
    path: '/404',
    name: 'Ошибка',
    meta: {
      layout: 'empty',
      auth: false
    },
    component: () => import('../views/Error404.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  let isLoggedin = true;

  const token = localStorage.getItem('token');
  const expiryDate = localStorage.getItem('expiryDate');
  if (!token || !expiryDate) {
    isLoggedin = false;
  }
  if (new Date(expiryDate) <= new Date()) {
    isLoggedin = false;
  }

  const requireAuth = to.matched.some(record => record.meta.auth);

  if (requireAuth && !isLoggedin) {
    // редирект на страницу логина
    next('/login');
  } else {
    next();
  }
});

export default router;
