import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';

import dateFilter from './filters/date.filter';

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();

Vue.filter('date', dateFilter);

Vue.use(Vuelidate);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
