<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline indigo lighten-2" primary-title>
          Уведомление
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Понятно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="timeout" persistent width="500">
      <v-card>
        <v-card-title class="headline indigo lighten-2" primary-title>
          Уведомление
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="startPage">
            Понятно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
      <v-btn color="indigo" text @click="snackbar = false">
        Закрыть
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  data: () => ({
    message: '',
    dialog: false,
    snackMessage: '',
    snackbar: false,
    timeout: false
  }),
  computed: {
    ...mapGetters(['messageData'])
  },
  watch: {
    messageData(val) {
      if (Object.keys(val).length > 0) {
        if (val.type === 'modal') {
          this.dialog = true;
          this.message = val.message;
        } else if (val.type === 'timeout') {
          this.timeout = true;
          this.message = val.message;
        } else {
          this.snackbar = true;
          this.snackMessage = val.message;
        }
        this.clearMessageData();
      }
    }
  },
  methods: {
    ...mapMutations(['clearMessageData']),
    startPage() {
      this.$router.push('/login');
    }
  }
};
</script>

<style></style>
