<template>
  <v-card color="#E1F5FE" class="card-block">
    <v-toolbar dense color="blue" dark>
      <v-toolbar-title>Последние события</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div v-if="!loading">
        <template v-for="item in events">
          <v-list-item
            dense
            link
            :key="item._id"
            :to="eventUrl(+item.messType, item.documentId)"
            v-model="selected"
            active-class="blue--text"
            multiple
            style="margin: 5px"
          >
            <v-list-item-avatar>
              <v-icon class="green lighten-4" dark> mdi-message </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{ item.message }}
              {{ item.partner ? ` (${item.partner.name})` : '' }}
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>{{
                item.createdAt | date('datetime')
              }}</v-list-item-action-text>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-information</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <div class="text-center">
          <v-pagination v-model="page" :length="pageLength"></v-pagination>
        </div>
      </div>
      <div v-else>
        <v-skeleton-loader class="mx-auto" type="article"></v-skeleton-loader>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { sendRequest } from '../../utils/graphql';
import { EVENTS, USER_ROLE, CONNECTION_STATUS } from '../../utils/mainConsts';

export default {
  name: 'EventsWarning',
  props: ['partnerId', 'partner'],
  data: () => ({
    loading: true,
    page: 1,
    limit: 5,
    pageLength: 1,
    selected: [],
    connections: [
      { name: 'Ожидает рассмотрения', value: 1 },
      { name: 'Всего', value: 9 }
    ],
    eventsText: EVENTS,
    events: []
  }),
  watch: {
    partnerId: function() {
      this.loadEvents();
    },
    page: function() {
      this.loadEvents();
    }
  },
  computed: {
    role() {
      return this.$store.state.role;
    }
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    eventUrl(message, id) {
      let url = '';
      switch (message) {
        case 0:
          url = `/connection/${id}`;
          break;
        case 1:
          url = `/connection/${id}`;
          break;
        case 2:
          url = `/connection/${id}`;
          break;
        case 3:
          url = `/connection/${id}/comments`;
          break;
        case 4:
          url = `/connection/${id}/docs`;
          break;
        case 6:
          url = `/connection/${id}/provider`;
          break;
        default:
          url = `/connection/${id}`;
      }
      return url;
    },
    modifyEventsMessages() {
      const version = this.role < 5 ? 1 : 0;
      this.events.forEach(e => {
        let addition = '';
        switch (e.general) {
          case 1: // изменен статус
            addition = ` ${CONNECTION_STATUS[e.message]}`;
            break;
          case 2: // новый входящий номер
            addition = `: ${e.message}`;
            break;
          case 3: // новый комментарий
            addition = `: ${e.message}`;
            break;
        }
        e.messType = e.general;
        e.message = EVENTS[e.general][version] + addition;
      });
    },
    loadEvents() {
      if (this.role < 5 || (this.role >= 5 && this.partner)) {
        this.loadByPartner();
      } else if (this.role >= 5) {
        this.loadAll();
      }
    },
    async loadByPartner() {
      const token = this.$store.state.token;
      const query = `query EventoFetchByPartner($id: String, $page: Int, $limit: Int) {
        eventoFetchByPartner(id: $id, page: $page, limit: $limit) {
          events {
            _id
            general
            type
            message
            documentId
            createdAt
            partner {
              name
            }
          }
          total
        }
      }`;
      const variables = {
        id: this.partnerId,
        page: this.page,
        limit: this.limit
      };

      this.loading = true;
      sendRequest(token, query, variables, result => {
        // Тут загружены все комментарии если они были
        const total = result.data.eventoFetchByPartner.total;
        this.events = result.data.eventoFetchByPartner.events;
        this.modifyEventsMessages();
        this.pageLength = Math.ceil(total / this.limit);
        this.loading = false;
      });
    },
    async loadAll() {
      const token = this.$store.state.token;
      const query = `query eventoFetchAll($page: Int, $limit: Int) {
        eventoFetchAll(page: $page, limit: $limit) {
          events {
            _id
            general
            type
            message
            documentId
            createdAt
            partner {
              name
            }
          }
          total
        }
      }`;
      const variables = {
        page: this.page,
        limit: this.limit
      };

      this.loading = true;
      sendRequest(token, query, variables, result => {
        // Тут загружены все комментарии если они были
        const total = result.data.eventoFetchAll.total;
        this.events = result.data.eventoFetchAll.events;
        this.modifyEventsMessages();
        this.pageLength = Math.ceil(total / this.limit);
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.card-block {
  margin: 20px 0;
}
</style>
