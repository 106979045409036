const state = {
  messageData: {}
}

const mutations = {
  setMessageData (state, data) {
    state.isMessage = true
    state.messageData = data
  },
  clearMessageData (state) {
    state.messageData = {}
  }
}

const getters = {
  messageData: (state) => {
    return state.messageData
  }
}

export default {
  state, getters, mutations
}
