<template>
  <div>
    <v-app-bar app color="white" height="90px" flat hide-on-scroll>
      <div class="header">
        <div class="logo">
          <a href="http://sibseti.su"><img src="logo.png" width="80"/></a>
        </div>
        <div class="name" v-if="fullscreen">
          <h1>Электрические сети Сибири</h1>
        </div>
      </div>
      <template v-slot:extension>
        <v-tabs centered optional>
          <v-tab href="http://sibseti.su/company.php">Главная</v-tab>
          <v-tab href="http://sibseti.su/consumer.php">Потребителям</v-tab>
          <v-tab href="http://sibseti.su/separate_account.php">Отчеты</v-tab>
          <v-tab href="http://sibseti.su/karta_prisutstviya.php"
            >Карта присутствия</v-tab
          >
          <v-tab href="/#/login">Личный кабинет</v-tab>
          <v-tab href="http://sibseti.su/calculator.php">Калькулятор</v-tab>
          <v-tab href="http://sibseti.su/map.php">Карта сайта</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <!-- <v-alert outlined type="warning" prominent border="left">
              Уважаемые посетители! Портал электросетевых услуг работает в
              тестовом режиме. Приносим извинения за возможные неудобства.
            </v-alert> -->
            <router-view />
            <alert-message />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer app absolute>
      <v-container fluid>
        <v-layout>
          <v-flex md2></v-flex>
          <v-flex md8 class="white--text pt-0 text-xs-center">
            <div class="telefon" v-if="isMobile">
              ООО "Электрические сети Сибири"
            </div>
            <div class="telefon" :style="footerStyle">
              8 (800) 700-46-71<br />Горячая линия
            </div>
          </v-flex>
          <v-flex md2></v-flex>
        </v-layout>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import AlertComponent from '@/components/Alert';

export default {
  name: 'emptyLayout',
  components: {
    'alert-message': AlertComponent
  },
  data: () => ({}),
  computed: {
    fullscreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    footerSize() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return '1.5em';
      } else {
        return '1.0em';
      }
    },
    footerStyle() {
      return { fontSize: this.footerSize };
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'HelveticaNeueCyr-Light';
  src: url('../fonts/HelveticaNeueCyr-Light.eot');
  src: local('☺'), url('../fonts/HelveticaNeueCyr-Light.woff') format('woff'),
    url('../fonts/HelveticaNeueCyr-Light.ttf') format('truetype'),
    url('../fonts/HelveticaNeueCyr-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

h1 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: #5a5a5a;
  font-family: HelveticaNeueCyr-Light, Arial, sans-serif;
  font-size: 2.875em;
}

.header {
  margin: 0 auto;
  padding-top: 10px;
}

.logo {
  float: left;
}

.name {
  float: left;
  margin-left: 10px;
  padding-top: 20px;
}

#footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-bottom: 30px;
  margin-left: -400px;
  height: 60px;
  width: 800px;
  clear: both;
}

.telefon {
  display: block;
  color: #5a5a5a;
  font-family: HelveticaNeueCyrLight, Arial, sans-serif;
  font-size: 1em;
  text-decoration: none;
  position: relative;
  text-align: center;
}

.author {
  float: right;
}
</style>
