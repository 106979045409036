<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <div v-for="item in items" :key="item.tag">
          <v-list-item v-if="+userRole >= item.level" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Портал электросетевых услуг</v-toolbar-title>
      <v-spacer />
      <login-buttons />
      <template v-slot:extension>
        <v-tabs align-with-title optional>
          <v-tab to="/" v-if="userRole === 2">Главная</v-tab>
          <v-tab to="/profile">Данные клиента</v-tab>
          <v-tab to="/connections" v-if="userRole !== 2">Заявка на техприсоединение</v-tab>
          <v-tab @click.prevent="logoutHandler"
            >Выход из личного кабинета</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer color="indigo" app>
      <span class="white--text"
        >ООО "Электрические сети Сибири" &copy; 2021 (HF)</span
      >
      <v-spacer />
      <span class="white--text"> Версия: {{ version }} </span>
    </v-footer>
    <alert-message />
  </div>
</template>

<script>
import loginButtons from '../components/LoginButtons';
import AlertComponent from '@/components/Alert';

const version = process.env.VUE_APP_VERSION;

export default {
  name: 'mainLayout',
  components: {
    'login-buttons': loginButtons,
    'alert-message': AlertComponent
  },
  data: () => ({
    version: version,
    drawer: false,
    items: [
      { title: 'Домой', icon: 'mdi-home', link: '/', level: 1 },
      {
        title: 'Справочники',
        icon: 'mdi-view-dashboard',
        link: '/dictionary',
        level: 3
      },
      {
        title: 'Документы',
        icon: 'mdi-view-dashboard',
        link: '/document',
        level: 9
      },
      {
        title: 'Заявки',
        icon: 'mdi-view-dashboard',
        link: '/connections',
        level: 1
      },
      {
        title: 'Мой профиль',
        icon: 'mdi-account-box',
        link: '/profile',
        level: 1
      }
    ]
  }),
  computed: {
    userRole() {
      return this.$store.state.role;
    }
  },
  methods: {
    logoutHandler() {
      this.$store.commit('setLogout');
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');

      this.$router.push('/login');
    },
    goTo(link) {
      this.$router.push(link);
    }
  }
};
</script>

<style></style>
