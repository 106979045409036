import { REPORT_TAG } from '../../utils/mainConsts';

const state = {
  // Перечисление всех. Это для меню. tag будет является переключателем
  reportList: [
    {
      // title: 'Наличие',
      // subtitle: 'Наличие товаров',
      // tag: REPORT_TAG.goods,
      // icon: 'mdi-grain',
      // iconClass: 'grey lighten-1 white--text',
      // show: true
    }
  ]
};

const getters = {
  reportList: state => {
    return state.reportList;
  }
};

export default {
  state,
  getters
};
