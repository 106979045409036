<template>
  <v-card color="#E1F5FE" class="card-block">
    <v-toolbar dense color="blue" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Совет</v-toolbar-title>
    </v-toolbar>
    <v-card-title>Заявление на технологическое присоединение</v-card-title>
    <v-card-text>
      Подать заявление на технологическое присоединение в
      <router-link to="/connections">разделе Заявки</router-link>.
    </v-card-text>
    <v-card-actions>
      <v-btn text color="light-blue darken-1" to="/connections/create">
        Подать заявление сейчас
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'InfoClientActions'
};
</script>

<style scoped>
.card-block {
  margin: 20px 0;
}
</style>
