import { sendRequest } from '../../utils/graphql';
import { capitalize } from '@/utils/helpers.js';
// import { DICTIONARY_TAG } from '../../utils/mainConsts.js';

const state = {
  defaultDataToFetch: {
    nomenclature: '_id name role',
    connection: `
      _id
      type
      date
      devNames
      partner {
        _id
        orgType
        name
        shortName
        director
        creationDate
        inn
        kpp
        ogrn
        snils
        passportNum
        passportOrg
        address
        telephone
        email
        bank
      }
      region
      address
      maxPower
      powerOwened
      powerBefore
      voltage
      voltage1
      voltage2
      voltage3
      pointsC {
        _id name value
      }
      pointsB {
        _id name value
      }
      pointsNum
      loadNature
      loadNatureFull
      transNum
      genNum
      generator
      armor
      armorValue
      reliability
      level1
      level2
      level3
      tempDates
      dates
      plans
      offers
      reason
      method
      files {
        _id fileName filePath tag subTitle subType
        createdAt
        createdBy {
          _id
        }
      }
      resultFiles {
        _id fileName filePath tag subTitle subType
        createdBy {
          _id
        }
      }
      sbitFiles {
        _id fileName filePath tag subTitle subType
        createdBy {
          _id
        }
      }
      personalAgreement
      timeline
      contractInfo
      status
      bailee {
        _id name
      }
      enterNum
      createdAt
    `,
    user: `
      _id
      name
      email
      password
      telephone
      avatar
      status
      active
      role
      partner {
        _id
        orgType
        name
        shortName
        director
        inn
        kpp
        snils
        ogrn
        creationDate
        passportNum
        passportOrg
        address
        bank
        telephone
        email
        bank
      }
    `,
    donor: `
      _id
      number
      mark
      model
      year
      bodyNumber
      engine
      wheel
      kpp
      price
      image {
        _id
        fileName
      }
    `,
    supply: `
      _id
      name
      nomenclature {
        _id
        name
      }
      donor {
        _id
        number
        mark
        model
        year
        bodyNumber
        engine
        wheel
        kpp
        price
      }
      condition
      mark
      model
      bodyNumber
      engine
      year
      manufacturer
      oemNumber
      number
      color
      x y z
      price
      image {
        _id fileName subTitle subType
      }
      comment
      createdBy {
        _id
        name
      }
    `,
    goods: `
      _id
      name
      nomenclature {
        _id
        name
      }
      donor {
        _id
        number
        mark
        model
        year
        bodyNumber
        engine
        wheel
        kpp
        price
      }
      condition
      mark
      model
      bodyNumber
      engine
      year
      manufacturer
      oemNumber
      number
      color
      x y z
      price
      image {
        _id fileName subTitle subType
      }
      comment
      createdBy {
        _id
        name
      }
    `,
    partner: `
      _id
      orgType
      name
      shortName
      director
      creationDate
      inn
      kpp
      ogrn
      snils
      creationDate
      passportNum
      passportOrg
      address
      telephone
      email
      bank
    `,
    image: '_id fileName subTitle subType',
  },

  allHeaders: {
    connection: [
      { text: 'Дата', value: 'date' },
      { text: 'Контрагент', value: 'partner' },
      { text: 'Вид заявки', value: 'type' },
      { text: 'Статус', value: 'status' },
    ],
    goods: [
      { text: 'Картинка', value: 'image' },
      { text: 'Наименование', value: 'name' },
      { text: 'Марка', value: 'mark' },
      { text: 'Модель', value: 'model' },
      { text: 'Цена', value: 'price' },
    ],
    image: [
      { text: 'Картинка', value: 'image' },
      { text: 'Описание', value: 'subTitle' },
    ],
    supply: [
      { text: 'Наименование', value: 'name' },
      { text: 'Картинка', value: 'image' },
      { text: 'Марка', value: 'mark' },
      { text: 'Модель', value: 'model' },
      { text: 'Цена', value: 'price' },
    ],
    nomenclature: [
      { text: 'Наименование', value: 'name' },
      { text: 'Тип', value: 'role' },
    ],
    donor: [
      { text: 'Номер', value: 'number' },
      { text: 'Картинка', value: 'image' },
      { text: 'Марка', value: 'mark' },
      { text: 'Модель', value: 'model' },
      { text: 'Год', value: 'year' },
    ],
    partner: [
      { text: 'Наименование', value: 'name' },
      { text: 'ИНН', value: 'inn' },
      { text: 'Адрес', value: 'address' },
    ],
    contract: [
      { text: 'Наименование', value: 'name' },
      { text: '№', value: 'number' },
      { text: 'Дата', value: 'date' },
    ],
    point: [
      { text: 'Наименование', value: 'name' },
      { text: 'Адрес', value: 'address' },
    ],
  },
};

const getters = {
  allHeaders: state => tag => {
    return state.allHeaders[tag];
  },
};

const actions = {
  // УНИВЕРСАЛЬНАЯ
  async uniFetchAll({ rootState, state }, { name, dataToFetch }) {
    const query = `{
      ${name}FetchAll {
        ${dataToFetch || state.defaultDataToFetch[name]}
      }
    }`;

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, {}, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data[`${name}FetchAll`]);
      });
    });
  },

  async uniFetch({ rootState, state }, { name, dataToFetch, id }) {
    const query = `
      query Fetch($id: String!) {
        ${name}Fetch(id: $id) {
          ${dataToFetch || state.defaultDataToFetch[name]}
        }
      }
    `;
    const variables = {
      id: id,
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
        }
        resolve(data.data[`${name}Fetch`]);
      });
    });
  },

  async uniAdd({ rootState, state }, { name, dataToFetch, item, custom }) {
    const cName = capitalize(name);
    const query = `
      mutation Add($itemInput: ${cName}Input!) {
        ${name}Create(itemInput: $itemInput) {
          ${dataToFetch || state.defaultDataToFetch[name]}
        }
      }
    `;

    const variables = {
      itemInput: {
        ...item,
        createdBy: rootState.userId,
      },
      ...custom,
    };
    // console.log(variables);

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data[`${name}Create`]);
      });
    });
  },

  async uniEdit({ rootState, state }, { name, dataToFetch, id, item }) {
    const cName = capitalize(name);
    const query = `
      mutation Edit($id: String!, $itemInput: ${cName}Input!) {
        ${name}Edit(id: $id, itemInput: $itemInput) {
          ${dataToFetch || state.defaultDataToFetch[name]}
        }
      }
    `;

    const variables = {
      id: id,
      itemInput: {
        ...item,
      },
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.errors) {
          const errorText = data.errors[0].data.reduce((summ, curr) => {
            return summ + curr.message + ', ';
          });
          console.log('Произошла ошибка:', errorText);
          reject(errorText);
        } else if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data[`${name}Edit`]);
      });
    });
  },

  async uniDelete({ rootState }, { name, item }) {
    const query = `
      mutation Remove($id: String!) {
        ${name}Remove(id: $id) {
          _id
        }
      }
    `;
    const variables = {
      id: item.id,
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  // УНИВЕРСАЛЬНОЕ конец
};
export default {
  state,
  actions,
  getters,
};
