<template>
  <v-card color="deep-orange lighten-5" class="card-block">
    <v-toolbar dense color="red" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Заполните данные клиента</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      Для использования сервисов необходимо заполнить
      <b>Персональные данные клиента</b> в
      <router-link to="/profile">Профиле</router-link>.
    </v-card-text>
    <v-card-actions>
      <v-btn text color="deep-orange accent-3" @click.prevent="go">
        Заполнить сейчас
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ProfileWarning",
  methods: {
    go() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style scoped>
.card-block {
  margin: 20px 0;
}
</style>
