<template>
  <div>
    <div v-if="!isLoggedIn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon large @click.prevent="goToLink('register')" v-on="on">
            <v-icon>mdi-clipboard-account</v-icon>
          </v-btn>
        </template>
        <span>Регистрация</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon large @click.prevent="goToLink('login')" v-on="on">
            <v-icon>mdi-login</v-icon>
          </v-btn>
        </template>
        <span>Вход</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon large @click.prevent="logoutHandler" v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Выход</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login-buttons',
  computed: {
    isLoggedIn() {
      let isLoggedin = true;

      const token = localStorage.getItem('token');
      const expiryDate = localStorage.getItem('expiryDate');
      if (!token || !expiryDate) {
        isLoggedin = false;
      }
      if (new Date(expiryDate) <= new Date()) {
        isLoggedin = false;
      }

      return isLoggedin;
    }
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    logoutHandler() {
      this.$store.commit('setLogout');
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');

      this.$router.push('/login');
    }
  }
};
</script>

<style></style>
