/* eslint-disable standard/no-callback-literal */
const PATH = process.env.VUE_APP_GRAPHQL;

const sendRequest = async (token, query, variables, callback) => {
  const graphqlQuery = {
    query,
    variables,
  };
  let resData;
  try {
    const res = await fetch(PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(graphqlQuery),
    });

    resData = await res.json();
    // console.log(resData);
    if (resData.errors) {
      let errMessage = '';
      resData.errors.forEach(e => {
        errMessage += e.message + ' \n';
      });
      throw new Error(errMessage);
    }

    // Запрос к БД выполнен
    if (callback) {
      return callback(resData);
    } else {
      return resData;
    }
  } catch (err) {
    console.error('Ошибка GQL:', err);
    if (callback) {
      return callback(resData);
    } else {
      return resData; // { error: err };
    }
  }
};

export { sendRequest };
