// Для формы подтверждения
export const CONFIRM_ACTION = {
  delete: 'delete',
};

// Для шины глобальных событий ($bus)
export const GLOBAL_EVENT = {
  updateDictionaryList: 'updateDictionaryList',
  updateDocumentsList: 'updateDocumentsList',
  confirmDelete: 'confirmDelete',
  openConfirmWindow: 'openConfirmWindow',
};

// Для переключений между глобальным разделами
export const ITEM_TYPE = {
  dictionary: 'dictionary',
  document: 'document',
  file: 'file',
  report: 'report',
};

// Для работы со справочниками
export const DICTIONARY_TAG = {
  contract: 'contract',
  donor: 'donor',
  nomenclature: 'nomenclature',
  partner: 'partner',
  point: 'point',
  user: 'user',
};

// Для работы с документами
export const DOCUMENT_TAG = {
  supply: 'supply',
  connection: 'connection',
};

export const FILE_TAG = {
  image: 'image',
};

export const REPORT_TAG = {
  goods: 'goods',
};

export const USER_ROLE = {
  0: 'Не подтвержден',
  1: 'Клиент',
  2: 'Гарантирующий поставщик',
  5: 'Инженер',
  9: 'Администратор',
};

export const CONNECTION = {
  status: [
    { text: 'Ожидает проверки', value: 0 },
    { text: 'Принято к рассмотрению', value: 1 },
    { text: 'Выставлен счет', value: 2 },
    { text: 'Выданы техусловия', value: 3 },
    { text: 'Исполнено', value: 4 },
    { text: 'Отклонено', value: 5 },
    { text: 'Передано в ПАО Красноярскэнергосбыт', value: 6 },
    { text: 'Принято в ПАО Красноярскэнергосбыт', value: 7 },
    { text: 'Рассмотрено ПАО Красноярскэнергосбыт', value: 8 }
  ],
  loadNature: [
    { text: 'бытовая', value: 1 },
    { text: 'промышленная', value: 2 },
    { text: 'прочая', value: 3 },
  ],
  reliability: [
    { text: 'Категория I', value: 1 },
    { text: 'Категория II', value: 2 },
    { text: 'Категория III', value: 3 },
  ],
  method: [
    { text: 'Лично', value: 1 },
    { text: 'По почте', value: 2 },
  ],
  reason: [
    { text: 'впервые вводимое в эксплуатацию', value: 1 },
    {
      text:
        'увеличение мощности ранее присоединенных энергопринимающих устройств',
      value: 2,
    },
    {
      text:
        'изменение точки присоединения, не влекущее пересмотр величины присоединенной мощности, но изменяющее схему внешнего электроснабжения',
      value: 3,
    },
    {
      text:
        'изменение вида производственной деятельности, не влекущее пересмотр величины присоединенной мощности, но изменяющее схему внешнего электроснабжения',
      value: 4,
    },
    {
      text: 'получение технических условий при смене собственника',
      value: 5,
    },
    { text: 'восстановление технических условий', value: 6 },
  ],
  type: [
    'Заявка юридического лица (индивидуального предпринимателя), физического лица на временное присоединение энергопринимающих устройств',
    'Заявка юридического лица (индивидуального предпринимателя), физического лица на присоединение энергопринимающих устройств',
    'Заявка юридического лица (индивидуального предпринимателя), физического лица на присоединение по одному источнику электроснабжения энергопринимающих устройств с максимальной мощностью до 150 кВт включительно',
    'Заявка физического лица на присоединение по одному источнику электроснабжения энергопринимающих устройств с максимальной мощностью до 15 кВт включительно (используемых для бытовых и иных нужд, не связанных с осуществлением предпринимательской деятельности)',
  ],
  typeShort: [
    'Временное техприсоединение',
    'Техприсоединение',
    'Техприсоединение до 150 кВт',
    'Техприсоединение физ. лиц до 15 кВт',
  ],
};

export const VALID_ERRORS = {
  required: 'Обязательно к заполнению',
  notMatch: 'Пароли не совпадают!',
  maxLength: val => `Не более ${val} символов!`,
  minLength: val => `Не менее ${val} символов!`,
  personal: 'Чтобы использовать сервисы, Вы должны согласиться!',
  orgType: 'Не выбран тип контрагента!',
  numeric: 'Допустимы только цифры 0..9',
  dateMatch: 'Формат даты должен быть ДД.ММ.ГГГГ (например 31.12.2021)',
  email: 'Некорректный формат email',
};

export const EVENTS = {
  0: [
    'Сформирована новая заявка на технологическое присоединение',
    'Заявка на технологическое подключение сформирована',
  ],
  1: ['Статус заявки изменен на', 'Статус заявки изменен на'],
  2: ['Заявке присвоен входящий номер', 'Заявке присвоен входящий номер'],
  3: ['К заявке получен новый комментарий', 'К заявке получен новый комментарий'],
  4: ['По заявке получен новый документ', 'По заявке получен новый документ'],
  5: ['Сформирован акт об осуществлении технологического присоединения', 'Сформирован акт об осуществлении технологического присоединения'],
  6: ['По заявке получен новый документ от ПАО Красноярскэнергосбыт', 'По заявке получен новый документ от ПАО Красноярскэнергосбыт'],
};

export const CONNECTION_STATUS = [
  'Ожидает проверки',
  'Принято к рассмотрению',
  'Выставлен счет',
  'Выданы техусловия',
  'Исполнено',
  'Отклонено',
  'Передано в ПАО Красноярскэнергосбыт',
  'Принято в ПАО Красноярскэнергосбыт',
  'Рассмотрено ПАО Красноярскэнергосбыт'
];
