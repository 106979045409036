/* eslint-disable standard/no-callback-literal */
import { sendRequest } from '../../utils/graphql';
import { capitalize } from '@/utils/helpers.js';
import { FILE_TAG } from '../../utils/mainConsts.js';

const PATH = process.env.VUE_APP_UPLOADIMAGE;

// Получаемые данные поумолчанию
const defaultFileDataToFetch = {
  image: '_id fileName subTitle subType',
};

const state = {
  fileList: [
    {
      title: 'Изображения',
      subtitle: 'Работа с изображениями',
      tag: FILE_TAG.image,
      icon: 'mdi-folder',
      iconClass: 'grey lighten-1 white--text',
      show: true,
      level: 3,
    },
  ],
  // Для каждого словаря тут формируются заголовки в таблице
  fileHeadersPrefabs: {
    image: [
      { text: 'Картинка', value: 'image' },
      { text: 'Описание', value: 'subTitle' },
    ],
  },
};

const getters = {
  fileList: state => {
    return state.fileList;
  },
  fileHeaders: state => name => {
    return state.fileHeadersPrefabs[name];
  },
};

const actions = {
  async uploadImage(context, { data, cb }) {
    try {
      const res = await fetch(PATH, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + context.rootState.token,
        },
        body: data,
      });

      const resData = await res.json();
      if (resData.errors) {
        let errMessage = '';
        resData.errors.forEach(e => {
          errMessage += e.message + ' \n';
        });
        throw new Error(errMessage);
      }
      if (resData.length > 0) {
        resData.forEach((file, index) => {
          const image = {
            fileName: file,
            subTitle: 'Запчасть',
            subType: 'parts',
            createdBy: context.rootState.userId,
          };
          let nextCb = null;

          if (index + 1 === resData.length) {
            nextCb = cb;
          }
          context.dispatch('fileAdd', {
            name: 'image',
            item: image,
            cb: nextCb,
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  // УНИВЕРСАЛЬНАЯ
  async fileFetchAll({ rootState }, { name, dataToFetch }) {
    const query = `{
      ${name}FetchAll {
        ${dataToFetch || defaultFileDataToFetch[name]}
      }
    }`;

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, {}, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data[`${name}FetchAll`]);
      });
    });
  },

  async fileFetch({ rootState }, { name, dataToFetch, id }) {
    const query = `
      query Fetch($id: String!) {
        ${name}Fetch(id: $id) {
          ${dataToFetch || defaultFileDataToFetch[name]}
        }
      }
    `;
    const variables = {
      id: id,
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data[`${name}Fetch`]);
      });
    });
  },

  async fileAdd({ rootState }, { name, dataToFetch, item, cb }) {
    const cName = capitalize(name);
    const query = `
      mutation Add($itemInput: ${cName}Input!) {
        ${name}Create(itemInput: $itemInput) {
          ${dataToFetch || defaultFileDataToFetch[name]}
        }
      }
    `;

    const variables = {
      itemInput: {
        ...item,
        createdBy: rootState.userId,
      },
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        cb();
        resolve(data.data[`${name}Create`]);
      });
    });
  },

  async fileEdit({ rootState }, { name, dataToFetch, id, item }) {
    const cName = capitalize(name);
    const query = `
      mutation Edit($id: String!, $itemInput: ${cName}Input!) {
        ${name}Edit(id: $id, itemInput: $itemInput) {
          ${dataToFetch || defaultFileDataToFetch[name]}
        }
      }
    `;

    const variables = {
      id: id,
      itemInput: {
        ...item,
        createdBy: rootState.userId,
      },
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data[`${name}Edit`]);
      });
    });
  },

  async fileDelete({ rootState }, { name, item }) {
    const query = `
      mutation Remove($id: String!) {
        ${name}Remove(id: $id) {
          _id
        }
      }
    `;
    const variables = {
      id: item._id,
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  // УНИВЕРСАЛЬНОЕ конец
};

export default {
  state,
  actions,
  getters,
};
