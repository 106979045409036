<template>
  <div class="home my_home">
    <h1>Личный кабинет</h1>
    <connections-warning v-if="role >= 5 && role != 2" />
    <profile-warning v-if="emptyPerson && role != 2" />
    <events-warning :partnerId="partner" v-if="role != 2" />
    <info-actions v-if="role < 5 && role != 2" />
<!--    <sbit-bill v-if="role === 2" />-->
  </div>
</template>

<script>
import ProfileWarning from "../components/ui/WarnProfile";
import ConnectionsWarning from "../components/ui/WarnConnections";
import InfoClientActions from "../components/ui/InfoClientActions";
import EventsWarning from "../components/ui/WarnConnectionsEvent";

// import SbitBill from "../components/SbitBill";

const { DICTIONARY_TAG } = require("../utils/mainConsts");

export default {
  name: "Home",
  data: () => ({
    emptyPerson: false,
    partner: "",
  }),
  components: {
    "profile-warning": ProfileWarning,
    "connections-warning": ConnectionsWarning,
    "info-actions": InfoClientActions,
    "events-warning": EventsWarning,
    // "sbit-bill": SbitBill,
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  async mounted() {
    if (this.role < 5 && this.$store.state.userId) {
      const id = this.$store.state.userId;
      const thisUser = await this.$store.dispatch("uniFetch", {
        name: DICTIONARY_TAG.user,
        id,
      });

      if (!thisUser || !thisUser.partner) {
        this.emptyPerson = true;
      } else {
        this.emptyPerson = false;
        this.partner = thisUser.partner._id;
      }
      // Если это сбыт, то редиркетим на /provider
      if (this.role === 2) {
        await this.$router.push('/provider');
      }
    } else {
      this.emptyPerson = false;
      this.partner = "none";
    }
  },
  methods: {},
};
</script>

<style scoped>
.my_home {
  margin: 20px;
}
</style>
