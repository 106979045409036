export const capitalize = s => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const selectedItemConvertor = (value, str, item) => {
  if (!value) {
    return;
  }
  // Строковое представление выбранного
  str = value.reduce((prev, curr) => {
    return prev + curr.number + ';';
  }, '');

  // Перечень выбранных объектов
  item = value.map(i => {
    return i._id;
  });
};

export const checkValid = (obj, ...fields) => {
  // console.log('Get', obj);
  let valid = true;
  for (const f of fields) {
    // console.log('Testing', f, obj[f]);
    if (obj[f].$invalid) {
      valid = false;
      // console.log('breaking');
      break;
    }
  }
  return valid;
};
