import { DOCUMENT_TAG } from '../../utils/mainConsts';

const state = {
  // Перечисление всех. Это для меню. tag будет является переключателем
  documentList: [
    // {
    //   title: 'Поставка',
    //   subtitle: 'Наименование товаров на продажу',
    //   tag: DOCUMENT_TAG.supply,
    //   icon: 'mdi-folder',
    //   iconClass: 'grey lighten-1 white--text',
    //   show: false
    // },
    {
      title: 'Техприсоединение',
      subtitle: 'Заявление на технологическое присоединение',
      tag: DOCUMENT_TAG.connection,
      icon: 'mdi-folder',
      iconClass: 'grey lighten-1 white--text',
      show: true,
      level: 1
    }
  ],
  // Для каждого тут формируются заголовки в таблице
  documentHeadersPrefabs: {
    supply: [{ text: 'Наименование', value: 'name' }]
  }
};

const getters = {
  documentsList: state => {
    return state.documentList;
  },
  documentsHeaders: state => name => {
    return state.documentHeadersPrefabs[name];
  }
};

export default {
  state,
  getters
};
