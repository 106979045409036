<template>
  <v-app id="app">
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import MainLayout from './layouts/mainLayout';
import EmptyLayout from './layouts/emptyLayout';

import { mapMutations } from 'vuex';
import { sendRequest } from './utils/graphql';

// Получает роль пользователя по запросу на сервер
const getUserRole = async (token, userId) => {
  const query = `
      query FetchUser($id: String!) {
        getUserData(id: $id) {
          role
        }
      }`;
  const variables = {
    id: userId
  };

  return new Promise((resolve, reject) => {
    sendRequest(token, query, variables, data => {
      if (data.error) {
        console.log('Произошла ошибка:', data.error);
        reject(data.error);
      }
      resolve(data.data.getUserData.role);
    });
  });
};

export default {
  name: 'App',

  components: {
    'main-layout': MainLayout,
    'empty-layout': EmptyLayout
  },
  data: () => ({}),
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    }
  },
  async mounted() {
    const token = localStorage.getItem('token');
    const expiryDate = localStorage.getItem('expiryDate');

    // Заголовок портала
    document.title = `Личный кабинет - ООО "Электрические сети Сибири"`;

    if (!token || !expiryDate) {
      this.logoutHandler();
      return;
    }

    if (new Date(expiryDate) <= new Date()) {
      this.logoutHandler();
      return;
    }
    const userId = localStorage.getItem('userId');
    const remainingMilliseconds =
      new Date(expiryDate).getTime() - new Date().getTime();

    // Имя данные из localStorage запрашиваем из базы данных на сервере какая роль у этого юзера
    const role = await getUserRole(token, userId);

    this.$store.commit('setLogin', {
      token: token,
      userId: userId,
      role: role
    });

    this.setAutoLogout(remainingMilliseconds);
  },
  methods: {
    ...mapMutations(['setMessageData']),
    logoutHandler() {
      this.$store.commit('setLogout');
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');
      // this.$router.push('/login'); // Редирект на логин
    },

    setAutoLogout(milliseconds) {
      setTimeout(() => {
        this.$store.commit('setLogout');
        localStorage.removeItem('token');
        localStorage.removeItem('expiryDate');
        localStorage.removeItem('userId');
        this.setMessageData({
          message:
            'Сеанс завершен из-за истечения времени активности. Пожалуйста войдите заново.',
          type: 'timeout'
        });
      }, milliseconds);
    }
  }
};
</script>
