<template>
  <v-card color="#E1F5FE" class="card-block">
    <v-toolbar dense color="blue" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Информация о заявках в системе</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Заявки</th>
              <th class="text-left">Количество</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in connections" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { sendRequest } from "../../utils/graphql";

export default {
  name: "ConnectionsWarning",
  data: () => ({
    connections: [
      { name: "Ожидает рассмотрения", value: 1 },
      { name: "Всего", value: 9 },
    ],
  }),
  async mounted() {
    const token = this.$store.state.token;
    const query = `{
      connectionGeneral {
        total awaiting
      }
    }`;
    const result = await sendRequest(token, query, {});
    this.connections[0].value = result.data.connectionGeneral.awaiting.toString();
    this.connections[1].value = result.data.connectionGeneral.total.toString();
  },
  methods: {},
};
</script>

<style scoped>
.card-block {
  margin: 20px 0;
}
</style>
