import { sendRequest } from '../../utils/graphql';
import { DICTIONARY_TAG } from '../../utils/mainConsts.js';

const state = {
  // Перечисление всех справочников. Это для меню. tag будет является переключателем для работы со словарем
  dictionariesList: [
    // {
    //   title: 'Номенклатура',
    //   subtitle: 'Наименование товаров на продажу',
    //   tag: DICTIONARY_TAG.nomenclature,
    //   icon: 'mdi-folder',
    //   iconClass: 'grey lighten-1 white--text',
    //   show: true
    // },
    // {
    //   title: 'Автомобили доноры',
    //   subtitle: 'Автомобили в разборку',
    //   tag: DICTIONARY_TAG.donor,
    //   icon: 'mdi-folder',
    //   iconClass: 'grey lighten-1 white--text',
    //   show: true
    // },
    {
      title: 'Контрагенты',
      subtitle: 'Партнеры организации',
      tag: DICTIONARY_TAG.partner,
      icon: 'mdi-folder',
      iconClass: 'grey lighten-1 white--text',
      show: true,
      level: 3
    },
    {
      title: 'Договоры',
      subtitle: 'Договоры с контрагентами',
      tag: DICTIONARY_TAG.contract,
      icon: 'mdi-folder',
      iconClass: 'grey lighten-1 white--text',
      show: true,
      level: 3
    },
    {
      title: 'Пользователи',
      subtitle: 'Пользователи данной системы системы',
      tag: DICTIONARY_TAG.user,
      icon: 'mdi-folder',
      iconClass: 'grey lighten-1 white--text',
      show: true,
      level: 9
    },
    {
      title: 'Объекты',
      subtitle: 'Движимые и неджвижимые объекты',
      tag: DICTIONARY_TAG.point,
      icon: 'mdi-folder',
      iconClass: 'grey lighten-1 white--text',
      show: true,
      level: 3
    }
  ],
  // Для каждого словаря тут формируются заголовки в таблице
  dictionaryHeadersPrefabs: {
    user: [
      { text: 'Наименование', value: 'name' },
      { text: 'Права', value: 'role' },
      { text: 'Почта', value: 'email' }
    ],
    nomenclature: [
      { text: 'Наименование', value: 'name' },
      { text: 'Тип', value: 'role' }
    ],
    donor: [
      { text: 'Номер', value: 'number' },
      { text: 'Картинка', value: 'image' },
      { text: 'Марка', value: 'mark' },
      { text: 'Модель', value: 'model' },
      { text: 'Год', value: 'year' }
    ],
    partner: [
      { text: 'Наименование', value: 'name' },
      { text: 'ИНН', value: 'inn' },
      { text: 'Адрес', value: 'address' }
    ],
    contract: [
      { text: 'Наименование', value: 'name' },
      { text: '№', value: 'number' },
      { text: 'Дата', value: 'date' }
    ],
    point: [
      { text: 'Наименование', value: 'name' },
      { text: 'Адрес', value: 'address' }
    ]
  }
};

const getters = {
  dictionariesList: state => {
    return state.dictionariesList;
  },
  dictionaryHeaders: state => name => {
    return state.dictionaryHeadersPrefabs[name];
  }
};

const actions = {
  // КОНТРАГЕНТЫ

  async partnerFetchAll({ rootState }) {
    const query = `{
          fetchPartners {
            _id
            orgType
            name
            shortName
            director
            creationDate
            inn
            kpp
            ogrn
            passportNum
            passportOrg
            address
            telephone
            email
          }
        }`;

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, {}, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.fetchPartners);
      });
    });
  },

  async partnerFetch({ rootState }, id) {
    const query = `
      query FetchPartner($id: String!) {
        fetchPartner(id: $id) {
          _id
          orgType
          name
          shortName
          director
          creationDate
          inn
          kpp
          ogrn
          passportNum
          passportOrg
          address
          telephone
          email
        }
      }`;
    const variables = {
      id: id
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  async partnerAdd({ rootState }, partner) {
    const query = `
      mutation CreateNewPartner($partnerInput: PartnerInput!) {
        createPartner(partnerInput: $partnerInput) {
          _id
          orgType
          name
          shortName
          director
          creationDate
          inn
          kpp
          ogrn
          passportNum
          passportOrg
          address
          telephone
          email
          createdBy {
            _id
            name
          }
        }
      }
    `;

    const variables = {
      partnerInput: {
        orgType: +partner.orgType,
        name: partner.name,
        shortName: partner.shortName,
        director: partner.director,
        creationDate: partner.creationDate,
        inn: partner.inn,
        kpp: partner.kpp,
        ogrn: partner.ogrn,
        passportNum: partner.passportNum,
        passportOrg: partner.passportOrg,
        address: partner.address,
        telephone: partner.telephone,
        email: partner.email,
        createdBy: rootState.userId
      }
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  async partnerEdit({ rootState }, { id, partner }) {
    const query = `
      mutation EditPartner($id: String!, $partnerInput: PartnerInput!) {
        editPartner(id: $id, partnerInput: $partnerInput) {
          _id
          orgType
          name
          shortName
          director
          creationDate
          inn
          kpp
          ogrn
          passportNum
          passportOrg
          address
          telephone
          email
          createdBy {
            _id
            name
          }
        }
      }
    `;

    const variables = {
      id: id,
      partnerInput: {
        orgType: +partner.orgType,
        name: partner.name,
        shortName: partner.shortName,
        director: partner.director,
        creationDate: partner.creationDate,
        inn: partner.inn,
        kpp: partner.kpp,
        ogrn: partner.ogrn,
        passportNum: partner.passportNum,
        passportOrg: partner.passportOrg,
        address: partner.address,
        telephone: partner.telephone,
        email: partner.email,
        createdBy: rootState.userId
      }
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  async partnerDelete({ rootState }, item) {
    const query = `
      mutation RemovePartner($id: String!) {
        removePartner(id: $id) {
          _id
        }
      }
    `;
    const variables = {
      id: item.id
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  // Конец КОНТРАГЕНТЫ

  // ОБЪЕКТЫ ДОГОВОРОВ

  async pointFetchAll({ rootState }) {
    const query = `{
          poiFetchAll {
            _id
            name
            address
            createdBy {
              _id
              name
            }
          }
        }`;

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, {}, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.poiFetchAll);
      });
    });
  },

  async pointFetch({ rootState }, id) {
    const query = `
      query FetchPOI($id: String!) {
        poiFetch(id: $id) {
          _id
          name
          address
          createdBy {
            _id
            name
          }
        }
      }`;
    const variables = {
      id: id
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.poiFetch);
      });
    });
  },

  async pointAdd({ rootState }, poi) {
    const query = `
      mutation CreateNewPOI($poiInput: POIInput!) {
        poiCreate(poiInput: $poiInput) {
          _id
          name
          address
          createdBy {
            _id
            name
          }
        }
      }
    `;

    const variables = {
      poiInput: {
        name: poi.name,
        address: poi.address,
        createdBy: rootState.userId
      }
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  async pointEdit({ rootState }, { id, poi }) {
    const query = `
      mutation EditPOI($id: String!, $poiInput: POIInput!) {
        poiEdit(id: $id, poiInput: $poiInput) {
          _id
          name
          address
          createdBy {
            _id
            name
          }
        }
      }
    `;

    const variables = {
      id: id,
      poiInput: {
        name: poi.name,
        address: poi.address,
        createdBy: rootState.userId
      }
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  async pointDelete({ rootState }, item) {
    const query = `
      mutation RemovePOI($id: String!) {
        poiRemove(id: $id) {
          _id
        }
      }
    `;
    const variables = {
      id: item.id
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  },

  // ОБЪЕКТЫ ДОГОВОРОВ конец

  // ДОГОВОРЫ

  async contractFetchAll({ rootState }) {
    const query = `{
      contractFetchAll {
        _id
        name
        number
        date
        expiresAt
        partner {
          _id
          name
        }
        poi {
          _id
          name
        }
        createdBy {
          _id
          name
        }
      }
    }`;

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, {}, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.contractFetchAll);
      });
    });
  },

  async contractFetch({ rootState }, id) {
    const query = `
      query FetchContract($id: String!) {
        contractFetch(id: $id) {
          _id
          name
          number
          date
          expiresAt
          partner {
            _id
            name
          }
          poi {
            _id
            name
          }
          createdBy {
            _id
            name
          }
        }
      }
    `;
    const variables = {
      id: id
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.contractFetch);
      });
    });
  },

  async contractAdd({ rootState }, item) {
    const query = `
      mutation AddContract($contractInput: ContractInput!) {
        contractAdd(contractInput: $contractInput) {
          _id
          name
          number
          date
          expiresAt
          partner {
            _id
          }
          poi {
            _id
          }
          createdBy {
            _id
            name
          }
        }
      }
    `;

    const variables = {
      contractInput: {
        name: item.name,
        date: item.date,
        number: item.number,
        partner: item.partner,
        poi: item.poi,
        expiresAt: item.expiresAt,
        createdBy: rootState.userId
      }
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.contractAdd);
      });
    });
  },

  async contractEdit({ rootState }, { id, item }) {
    const query = `
      mutation EditContract($id: String!, $contractInput: ContractInput!) {
        contractEdit(id: $id, contractInput: $contractInput) {
          _id
          name
          number
          date
          expiresAt
          partner {
            _id
          }
          poi {
            _id
          }
          createdBy {
            _id
            name
          }
        }
      }
    `;

    const variables = {
      id: id,
      contractInput: {
        name: item.name,
        date: item.date,
        number: item.number,
        partner: item.partner,
        poi: item.poi,
        expiresAt: item.expiresAt,
        createdBy: rootState.userId
      }
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data.data.contractEdit);
      });
    });
  },

  async contractDelete({ rootState }, item) {
    const query = `
      mutation RemoveContract($id: String!) {
        contractRemove(id: $id) {
          _id
        }
      }
    `;
    const variables = {
      id: item.id
    };

    return new Promise((resolve, reject) => {
      sendRequest(rootState.token, query, variables, data => {
        if (data.error) {
          console.log('Произошла ошибка:', data.error);
          reject(data.error);
        }
        resolve(data);
      });
    });
  }
  // ДОГОВОРЫ конец
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
